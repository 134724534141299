<template>
  <el-dialog
    :title="'【详情】车队信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" label-width="80px" size="small">
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="车队编号" prop="motorcadeCode">
            <el-input v-model="dataForm.motorcadeCode" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属公司" prop="companyName">
            <el-input v-model="dataForm.companyName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="车队全称" prop="fullName">
            <el-input v-model="dataForm.fullName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车队简称" prop="simpleName">
            <el-input v-model="dataForm.simpleName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="联系人" prop="contactName">
            <el-input v-model="dataForm.contactName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="contactPhone">
            <el-input v-model="dataForm.contactPhone" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="地址" prop="address">
            <el-input v-model="dataForm.address" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" :rows="5" v-model="dataForm.remark" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        motorcadeCode: null,
        companyName: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        fullName: null,
        address: null,
        remark: null,
        status: 1
      }
    }
  },
  methods: {
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/motorcade/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.motorcadeCode = data.motorcadeCode
            this.dataForm.companyName = data.companyName
            this.dataForm.fullName = data.fullName
            this.dataForm.simpleName = data.simpleName
            this.dataForm.address = data.address
            this.dataForm.contactName = data.contactName
            this.dataForm.contactPhone = data.contactPhone
            this.dataForm.remark = data.remark
          }
        })
      }
    }
  }
}
</script>
